export const styles = {
    projectIntroImageContainer: {
        pt: '2rem',
        pb: '2rem',
        // margin: '-24px',
        width: 'initial',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    projectIntroImageBox: {
        width: '100%',
        mt: '10vh',
        mb: '10vh',
        display: 'flex',
        justifyContent: 'center'
    },
    projectIntroImage: {
        maxWidth: '1200px',
        width: 'inherit'
    },
};