import { Container } from "@mui/material";

import ProjectData from "./project_data.json";
import ProjectTitle from "../SharedComponents/ProjectTitle";
import IntroImage from "../SharedComponents/IntroImage";
import Details from "../SharedComponents/Details"
import ProjectOverview from "../SharedComponents/ProjectOverview";
import Needs from "../SharedComponents/Needs";
import Goal from "../SharedComponents/Goal";
import StudentDesign from "./StudentDesign";
import FacultyDesign from "./FacultyDesign";
import YoutubePlayer from "./YoutubePlayer";
import Retrospective from "../SharedComponents/Retrospective";
import { styles } from "./styles";
import "./styles.css";

export default function WT_SHSI() {
    return (
        <Container maxWidth="x1" sx={styles.projectPageContainer}>
            <ProjectTitle title={ProjectData.title} subTitle={ProjectData.subTitle} />
            <IntroImage img={ProjectData.imgSrc} />
            <Details details={ProjectData.details} />
            <ProjectOverview overview={ProjectData.overview} />
            <Needs needs={ProjectData.needs}/>
            <Goal goals={ProjectData.goals} />
            <StudentDesign />
            <FacultyDesign />
            <YoutubePlayer />
            <Retrospective retrospectives={ProjectData.retrospectives} />
        </Container>
    );
}