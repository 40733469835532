export const styles = {
    retrospectiveContainer: {
        mb: '10rem'
    },
    retrospectiveTitle: {
        fontFamily: 'inherit',
        fontWeight: 500,
        mb: '2rem'
    },
    retrospectiveBox: {
        mt: '2rem'
    },
    retrospectiveSubtitle: {
        fontFamily: 'inherit',
        fontWeight: 500,
        mb: '1rem'
    },
    retrospectiveDescription: {
        fontFamily: 'inherit',
        fontWeight: 400
    }
};