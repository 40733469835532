import { Box } from "@mui/material";

import { styles } from "./styles.js";
import "./styles.css";

export default function LaptopDemo(){
    return (
        <Box maxWidth="unset" sx={styles.laptopDemoBox} className="laptopDemoBox">
            <Box
                component="img"
                src={require("./img/facultyDemo.png")}
                className="laptopDemoFrame"
            >
            </Box>
        </Box>
    );
}