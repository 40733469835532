export const styles = {
    projectPageContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignContent: 'flex-start'
    },
    studentDesignTitle: {
        mb: '4rem',
        fontWeight: 500,
        fontFamily: 'inherit'
    },
    studentDesignBox: {
        display: 'flex',
        mb: '10rem'
    },
    mobileDemoContainer: {
        display: 'flex',
        // width: '100%',
    },
    mobileDemoBox: {
        width: 'fit-content',
        position: 'relative'
    },
    studentDesignDetailBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    studentDesignDetailTitle: {
        fontFamily: 'inherit',
        fontWeight: 500,
        mb: '1rem'
    },
    studentDesignDetail: {
        fontFamily: 'inherit',
        fontWeight: 400
    },
    facultyDesignTitle: {
        mb: '4rem',
        fontWeight: 500,
        fontFamily: 'inherit'
    },
    facultyDesignBox: {
        display: 'flex',
        mb: '10rem'
    },
    laptopDemoBox: {
        width: '100%',
        display: 'flex',
        // justifyContent: 'center'
    },
    facultyDesignDetailBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    facultyDesignDetailTitle: {
        fontFamily: 'inherit',
        fontWeight: 500,
        mb: '1rem'
    },
    facultyDesignDetail: {
        fontFamily: 'inherit',
        fontWeight: 400
    },
    youtubePlayerContainer: {
        position: 'relative',
        width: '100%',
        pb: '56.25%',
        mb: '10rem'
    }
};