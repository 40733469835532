import AiTudier from "./AiTudier";
import UniMarket from "./UniMarket";
import HostFam from "./HostFam";
import WT_SHSI from "./WT_SHSI";
import KimsFlights from "./Kims_Flights";
import StockTradingSimulator from "./Stock_Trading_Simulator";

const ProjectDetailPage = [
    { path: "/AiTudier", element: <AiTudier /> },
    { path: "/UniMarket", element: <UniMarket /> },
    { path: "/HostFam", element: <HostFam /> },
    { path: "/Westtown_Study_Hall_Sign_In", element: <WT_SHSI /> },
    { path: "/Kims_Flights", element: <KimsFlights /> },
    { path: "/Stock_Trading_Simulator", element: <StockTradingSimulator />}
];

export default ProjectDetailPage.flat();