export const styles = {
    projectDesignContainer: {
        mb: '10rem'
    },
    projectDesignTitle: {
        fontFamily: 'inherit',
        fontWeight: 500,
        mb: '2rem'
    },
    projectDesignContentWireframe: {
        backgroundColor: '#f4f0ec',
        overflow: 'hidden',
        width: 'inherit'
    },
    projectDesignContentBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        mb: '5rem',
    },
    projectDesignContentTitle: {
        width: '100%',
        fontFamily: 'inherit',
        fontWeight: 500,
        mb: '1rem'
    },
    projectDesignContentText: {
        width: '100%',
        fontFamily: 'inherit',
        fontWeight: 400,
        mb: '2rem'
    },
    siteMapImage: {
        width: 'inherit',
        maxWidth: '1200px'
    },
    wireframeImage: {
        width: 'inherit',
    }
};