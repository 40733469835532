export const styles = {
    proejectFinalProductContainer: {
        mb: '10rem'
    },
    projectFinalProductDemoContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    projectFinalProductTitle: {
        fontFamily: 'inherit',
        fontWeight: 600
    },
    projectFinalProductDemoTitle: {
        fontFamily: 'inherit',
        fontWeight: 500,
        mb: '1rem',
    },
    projectFinalProductDemoDescription: {
        fontFamily: 'inherit',
        fontWeight: 400,
        mb: '2rem',
        textAlign: 'left'
    }
};