import { Container, Box, Typography } from "@mui/material";

import VisibilityMotion from "../SharedComponents/ComponentMotion/VisibilityMotion";
import MobileDemo from "./MobileDemo";
import { styles } from "./styles";
import "./styles.css";

export default function StudentDesign() {
    return (
        <VisibilityMotion component=
            {
                <Container maxWidth="unset" sx={styles.studentDesignContainer} className="studentDesignContainer">
                    <Typography
                        variant="h4"
                        sx={styles.studentDesignTitle}
                    >
                        Final Web App for Students
                    </Typography>
                    <Box sx={styles.studentDesignBox} className="studentDesignBox">
                        <MobileDemo />
                        <Box sx={styles.studentDesignDetailBox} className="studentDesignDetailBox">
                            <Typography
                                variant="h5"
                                sx={styles.studentDesignDetailTitle}
                            >
                                Changed the process of sign-in
                            </Typography>
                            <Typography
                                variant="h6"
                                sx={styles.studentDesignDetail}
                            >
                                While testing out the service, students were very satisfied with the simple process.
                                There was no need of waiting on a line to sign-in for study hall.
                            </Typography>
                        </Box>
                    </Box>
                </Container>
            }
        />
    );
}