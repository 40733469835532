export const styles = {
  projectDevelopmentContainer: {
    mb: "10rem",
  },
  projectDevelopmentTitle: {
    fontFamily: "inherit",
    fontWeight: 500,
    mb: "2rem",
  },
  projectDevelopmentDetailBox: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    mb: "1rem",
  },
  projectDevelopmentDetailTitle: {
    width: "100%",
    fontFamily: "inherit",
    fontWeight: 500,
    mb: "1rem",
  },
  projectDevelopmentDetailContainer: {
    width: "100%",
    mb: "2rem",
  },
  projectDevelopmentDetailText: {
    width: "100%",
    fontFamily: "inherit",
    fontWeight: 400,
  },
  projectDevelopmentDetailImageContainer: {
    width: "100%",
    // display: "flex",
    // flexDirection: "column"
    maxWidth: "960px"
  },
  projectDevelopmentDetailImage: {
    maxWidth: "100%",
    // width: "100%",
    // maxWidth: "960px",
    height: "auto",
    // width: "inherit",
    mt: "1rem"
  },
}
