import { Container, Box, Typography } from "@mui/material";

import LaptopDemo from "./LaptopDemo";
import { styles } from "./styles";
import "./styles.css";

export default function FacultyDesign() {
    return (
        <Container maxWidth="unset" sx={styles.facultyDesignContainer} className="facultyDesignContainer">
            <Typography
                variant="h4"
                sx={styles.facultyDesignTitle}
            >
                Final Web App for Faculty Members
            </Typography>
            <Box sx={styles.facultyDesignBox} className="facultyDesignBox">
                <LaptopDemo />
                <Box sx={styles.facultyDesignDetailBox} className="facultyDesignDetailBox">
                    <Typography
                        variant="h5"
                        sx={styles.facultyDesignDetailTitle}
                    >
                        Easily locate and find students
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={styles.facultyDesignDetail}
                    >
                        Faculty members were struggling finding students' names on Google Sheet.
                        However with our service, they easily found students and signed students in a few seconds.
                    </Typography>
                </Box>
            </Box>
        </Container>
    );
}