import { Container, Typography } from "@mui/material"
import Typewriter from "typewriter-effect"

import { styles } from "./styles"
import "./styles.css"

export default function Intro() {
  const descriptions = [
    "I major in Computer Science at New York University.",
    "I minor in Management and Integrated Design & Media.",
    "I was a Software Engineer Intern at EdgeworthBox.",
    "I was a Full-Stack Web Developer at Dongkook.",
    "I was a Technical Product Manager of UniMarket.",
  ]

  return (
    <Container
      maxWidth="x1"
      sx={styles.introContainer}
      className="introContainer"
    >
      <Typography variant="h4" sx={styles.introTitle} className="introTitle">
        Hi 🙋‍♂️ my name is
      </Typography>
      <Typography
        variant="h1"
        sx={styles.introTitleName}
        className="introTitleName"
      >
        Brian Kim.
      </Typography>
      <Typography
        variant="h4"
        sx={styles.introTitleDetail}
        className="introTitleDetail"
      >
        <Typewriter
          options={{
            strings: descriptions,
            autoStart: true,
            loop: true,
            delay: 90,
            deleteSpeed: 30,
          }}
        />
        <span style={styles.introTitleDetailPreset}>
          I major in Computer Science at New York University..
        </span>
      </Typography>
    </Container>
  )
}
