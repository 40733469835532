import { Container, Typography } from '@mui/material';

import { styles } from "./styles";
import "./styles.css";

function ContactPage() {
    return (
        <Container maxWidth="x1" sx={styles.contactContainer} className="contactContainer">
            <Container maxWidth='unset' sx={styles.contactInnerContainer}>
                <Typography
                    variant="h2"
                    sx={styles.contactText}
                    className="contactText"
                >
                    Reach me <a href="mailto:briankim00119@gmail.com" style={styles.contactEmail}>here</a> to talk about my passion, food, and anything in between.
                </Typography>
            </Container>
        </Container>
    );
}

export default ContactPage;