import { Container } from "@mui/material"

import ProjectData from "./project_data.json"
import ProjectTitle from "../SharedComponents/ProjectTitle"
import IntroImage from "../SharedComponents/IntroImage"
import Details from "../SharedComponents/Details"
import ProjectOverview from "../SharedComponents/ProjectOverview"
import Goal from "../SharedComponents/Goal"
import Retrospective from "../SharedComponents/Retrospective"
import DevelopmentProcess from "../SharedComponents/DevelopmentProcess"

export default function StockTradingSimulator() {
  return (
    <Container maxWidth="x1">
      <ProjectTitle
        title={ProjectData.title}
        subTitle={ProjectData.subTitle}
        projectLink={ProjectData.projectLink}
        projectLinkTitle="View the prototype"
      />
      <IntroImage img={ProjectData.imgSrc} />
      <Details details={ProjectData.details} />
      <ProjectOverview overview={ProjectData.overview} />
      <Goal goals={ProjectData.goals} />
      <DevelopmentProcess development={ProjectData.development} />
      <Retrospective retrospectives={ProjectData.retrospectives} />
    </Container>
  )
}