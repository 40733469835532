export const styles = {
    projectPageTitleContainer: {
        mt: '10vh',
        mb: '10vh'
    },
    projectPageTitle: {
        fontFamily: 'inherit',
        textAlign: 'center'
    },
    projectPageSubtitle: {
        fontFamily: 'inherit',
        textAlign: 'center',
        mt: '2rem'
    },
    projectLinkButtonBox: {
        width: 'inherit',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        mt: '2rem'
    },
    projectLinkButton: {
        fontFamily: 'inherit',
        color: 'var(--brown)',
    }
};