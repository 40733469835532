import {
  Container,
  Box,
  Typography,
  Card,
  CardMedia,
  Button,
} from "@mui/material";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

import ProjectList from "./project_list.json";
import { styles } from "./styles";
import "./styles.css";

export default function Projects() {
  const navigate = useNavigate();

  //DELETE WHEN ALL PROJECT PAGES ARE DONE!
  function handlePage(dir, name, projectUrl) {
    if (projectUrl) {
      window.open(projectUrl, "_blank");
      return;
    }
    switch (dir) {
      case "/EdgeworthBox":
        if (
          window.confirm(
            "Sorry, I can only show one of the projects I completed at EdgeworthBox. \nWould you like to check out the generic version?"
          )
        ) {
          window.open("https://github.com/bk00119/form_generator", "_blank");
        }
        break;
      case "/SamsungElectronics":
      case "/AiTudier":
      case "/Dongkook":
        alert(`Please contact me to hear more about my experience at ${name}`);
        break;
      case "/UniMarket":
        if (
          window.confirm(
            "Sorry, this project is a work in progress!\nWould you like to check out the prototype?"
          )
        ) {
          window.open(
            "https://www.figma.com/file/fYN5WrX8b1uaIOun3jaM10/UniMarket-High-Fi?node-id=0%3A1",
            "_blank"
          );
        }
        break;
      case "/Telecharge":
        comingSoonMessage();
        break;
      default:
        navigate(dir);
    }
  }
  //DELETE WHEN ALL PROJECT PAGES ARE DONE!
  function comingSoonMessage() {
    alert("Sorry this page is a work in progress!");
  }

  return (
    <Container sx={styles.proejctGrid} className="proejctGrid">
      {ProjectList.map((project) => (
        <Box key={project.name} sx={styles.projectBox} className="projectBox">
          <motion.div
            whileHover={{ scale: 0.95 }}
            style={styles.projectCardMotion}
          >
            <Card
              sx={styles.projectCard}
              className="projectCard"
              // onClick={()=>navigate(project.dir)}
              onClick={() =>
                handlePage(project.dir, project.name, project.projectUrl)
              }
            >
              <CardMedia
                component="img"
                image={require(`${project.image}`)}
                alt={project.name + " cover image"}
                sx={styles.projectImage}
              />
            </Card>
          </motion.div>
          <Box sx={styles.projectTextBox}>
            <Box>
              <Typography
                variant="h5"
                sx={styles.projectTitle}
                className="projectTitle"
              >
                {project.name}
              </Typography>
              <Typography
                variant="h6"
                sx={styles.projectDescription}
                className="projectDescription"
              >
                {project.shortDescription}
              </Typography>
              <Box sx={styles.projectKeywordBox} className="projectKeywordBox">
                <Typography
                  variant="h7"
                  sx={styles.projectPosition}
                  className="projectPosition"
                >
                  #{project.position}
                </Typography>

                {project.tags?.map((tag) => (
                  <Typography
                    variant="h7"
                    sx={styles.projectLanguage}
                    className="projectLanguage"
                    key={tag}
                  >
                    #{tag}
                  </Typography>
                ))}
              </Box>
            </Box>
            <Box sx={styles.projectButtonBox}>
              <Button
                size="medium"
                variant="text"
                sx={styles.projectButton}
                className="projectButton"
                // onClick={()=>navigate(project.dir)}
                onClick={() => handlePage(project.dir)}
              >
                Read More
              </Button>
            </Box>
          </Box>
        </Box>
      ))}
    </Container>
  );
}
