import { Container, Box, Typography, List } from "@mui/material";

import VisibilityMotion from "../ComponentMotion/VisibilityMotion";
import { styles } from "./styles";
import "./styles.css";

export default function Details(props){
    return (
        <Container maxWidth="unset">
            <VisibilityMotion component=
                {
                    <Box sx={styles.projectDetailBox} className="projectDetailBox" >
                        <Box sx={styles.projectDetailsLeft} className="projectDetailsLeft" >
                            {props.details.leftCol.map((detail, index) => (
                                <Box key={index}>
                                    <Typography
                                        variant="h5"
                                        sx={styles.projectDetailTitle}
                                    >
                                        {detail.title}
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        sx={styles.projectDetailInfo}
                                    >
                                        {detail.text}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                        <Box sx={styles.projectDetailsRight} className="projectDetailsRight" >
                            <Box>
                                <Typography
                                        variant="h5"
                                        sx={styles.projectDetailTitle}
                                    >
                                        {props.details.rightCol.title} 
                                </Typography>
                                {props.details.rightCol.text.map((detail, index)=>(
                                    <List component="ul" sx={styles.projectDetailRoleList} key={index}>
                                        <Typography
                                            component="li"
                                            variant="h6"
                                            sx={styles.projectDetailRoleInfo}
                                        >
                                            {detail}
                                        </Typography>
                                    </List>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                }
            />

        </Container>
    );
}