export const styles = {
    goalContainer: {
        mb: '10rem'
    },
    goalTitle: {
        fontFamily: 'inherit',
        fontWeight: 500,
        mb: '1.5rem'
    },
    goalBox: {
        mt: '1.5rem'
    },
    goalSubtitle: {
        fontFamily: 'inherit',
        fontWeight: 500,
        mb: '1rem'
    },
    goalDescription: {
        fontFamily: 'inherit',
        fontWeight: 400
    }
};