import { Container, Box, Typography } from "@mui/material";

import VisibilityMotion from "../ComponentMotion/VisibilityMotion";
import { styles } from "./styles";
import "./styles.css";

export default function Retrospective(props){
    return (
        <Container maxWidth="unset" sx={styles.retrospectiveContainer} className="retrospectiveContainer">
            <VisibilityMotion component=
                {
                    <Box>
                        <Typography
                            variant='h4'
                            sx={styles.retrospectiveTitle}
                        >
                            Retrospective
                        </Typography>
                        {props.retrospectives.map((retrospective, index)=>(
                            <Box
                                key={index}
                                sx={styles.retrospectiveBox}
                            >
                                <Typography
                                    variant='h5'
                                    sx={styles.retrospectiveSubtitle}
                                >
                                    {retrospective.title}
                                </Typography>
                                <Typography
                                    variant='h6'
                                    sx={styles.retrospectiveDescription}
                                >
                                    {retrospective.description}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                }
            />
        </Container>
    );
}