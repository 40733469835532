export const styles = {
    contactContainer: {
        backgroundColor: 'var(--brown)',
        mt: '-3.5rem'
    },
    contactInnerContainer: {
        minHeight: '70vh',
        display: 'flex',
        height: 'inherit',
        alignItems: 'center',
        justifyContent: 'center'
    },
    contactText: {
        fontFamily: 'inherit',
        color: 'white',
        maxWidth: '1200px'
    },
    contactEmail: {
        color: 'inherit',
        textDecorationThickness: 'from-font'
    }
};