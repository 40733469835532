import { Container, Box, Typography } from "@mui/material";

import VisibilityMotion from "../ComponentMotion/VisibilityMotion";
import { styles } from "./styles";
import "./styles.css";

export default function DesignProcess(props){
    return (
        <Container maxWidth="unset" sx={styles.projectDesignContainer} >
            <VisibilityMotion component=
                {
                    <Box sx={styles.projectDesignBox} className="projectDesignBox" >
                        <Typography
                            variant='h4'
                            sx={styles.projectDesignTitle}
                        >
                            Design Process
                        </Typography>
                        <Box sx={styles.projectDesignContent} >
                            {"siteMap" in props.design ? (
                                <Box sx={styles.projectDesignContentBox} >
                                    <Typography
                                        variant='h5'
                                        sx={styles.projectDesignContentTitle}
                                    >
                                        Site Map
                                    </Typography>
                                    <Typography
                                        variant='h6'
                                        sx={styles.projectDesignContentText}
                                    >
                                        {props.design.siteMap.text}
                                    </Typography>
                                    <Box
                                        component="img"
                                        src={require("../../"+props.design.siteMap.imgSrc)} //DIR: "../../[Project Name]/img/[file]", require() can't get files from the parent directories when using variable, props.img
                                        alt="Site Map Diagram"
                                        sx={styles.siteMapImage}
                                        className="siteMapImage"
                                    />
                                </Box>
                            ) : <></>}
                        </Box>
                        <Box sx={styles.projectDesignContentWireframe} className="projectDesignContentWireframe" >
                            {"wireframe" in props.design ? (
                                <Box sx={styles.projectDesignContentBox} >
                                    <Typography
                                        variant='h5'
                                        sx={styles.projectDesignContentTitle}
                                    >
                                        Wireframe Prototype
                                    </Typography>
                                    <Typography
                                        variant='h6'
                                        sx={styles.projectDesignContentText}
                                    >
                                        {props.design.wireframe.text}
                                    </Typography>
                                    <Box
                                        component="img"
                                        src={require("../../"+props.design.wireframe.imgSrc)} //DIR: "../../[Project Name]/img/[file]", require() can't get files from the parent directories when using variable, props.img
                                        alt="Wireframe Diagram"
                                        sx={styles.wireframeImage}
                                        className="wireframeImage"
                                    />
                                </Box>
                            ) : <></>}
                        </Box>
                    </Box>
                }
            />
        </Container>
    )
}