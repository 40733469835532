import { Container, Box, Typography } from "@mui/material";

import VisibilityMotion from "../ComponentMotion/VisibilityMotion";
import { styles } from "./styles";
import "./styles.css";

export default function FinalProduct(props) {
    return (
        <Container maxWidth="unset" sx={styles.proejectFinalProductContainer} className="proejectFinalProductContainer">
            <VisibilityMotion component=
                {
                    <Box sx={styles.projectFinalProductBox} className="projectFinalProductBox" >
                        <Typography
                            variant='h4'
                            sx={styles.projectFinalProductTitle}
                            className="projectFinalProductTitle"
                        >
                            Final Product
                        </Typography>
                        <Box sx={styles.projectFinalProductContent} >
                            {props.finalProduct.demoVideos.map((demo, index)=> (
                                <Box 
                                    sx={styles.projectFinalProductDemoContainer} 
                                    className="projectFinalProductDemoContainer"
                                    key={index}
                                >
                                    <Box sx={styles.projectFinalProductDemoTextBox} className="projectFinalProductDemoTextBox">
                                        <Typography
                                            variant='h5'
                                            sx={styles.projectFinalProductDemoTitle}
                                        >
                                            {demo.title}
                                        </Typography>
                                        <Typography
                                            variant='h6'
                                            sx={styles.projectFinalProductDemoDescription}
                                        >
                                            {demo.description}
                                        </Typography>
                                    </Box>
                                    <Box sx={styles.projectFinalProductDemoBox} className={demo.className+" projectFinalProductDemoBox"}>
                                        <video autoPlay loop muted playsInline>
                                            <source src={require("../../"+demo.mediaSrc)} type={demo.type} />
                                        </video>
                                    </Box>
                                </Box>
                            ))}                        
                        </Box>
                    </Box>
                }
            />
        </Container>
    );
}