import { Container, Box, Typography } from "@mui/material";

import VisibilityMotion from "../ComponentMotion/VisibilityMotion";
import { styles } from "./styles";
import "./styles.css";

export default function Goal(props){
    return (
        <Container maxWidth="unset" sx={styles.goalContainer} className="goalContainer">
            <VisibilityMotion component=
                {
                    <Box>
                        <Typography
                            variant='h4'
                            sx={styles.goalTitle}
                        >
                            Project Goal
                        </Typography>
                        {props.goals.map((goal, index)=>(
                            <Box
                                key={index}
                                sx={styles.goalBox}
                            >
                                <Typography
                                    variant='h5'
                                    sx={styles.goalSubtitle}
                                >
                                    {goal.title}
                                </Typography>
                                <Typography
                                    variant='h6'
                                    sx={styles.goalDescription}
                                >
                                    {goal.description}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                }
            />
        </Container>
    );
}