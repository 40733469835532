import { Container, Box, Typography } from "@mui/material"

import VisibilityMotion from "../ComponentMotion/VisibilityMotion"
import { styles } from "./styles"
import "./styles.css"

export default function DevelopmentProcess(props) {
  return (
    <Container maxWidth="unset" sx={styles.projectDevelopmentContainer}>
      <VisibilityMotion
        component={
          <Box
            sx={styles.projectDevelopmentBox}
            className="projectDevelopmentBox"
          >
            <Typography variant="h4" sx={styles.projectDevelopmentTitle}>
              Development Process
            </Typography>
            <Box sx={styles.projectDevelopmentContentBox}>
              {props.development.map((detail, index) => (
                <Box sx={styles.projectDevelopmentDetailBox} key={index}>
                  <Typography
                    variant="h5"
                    sx={styles.projectDevelopmentDetailTitle}
                  >
                    {detail.title}
                  </Typography>
                  {detail.descriptions.map((item, index) => (
                    <Box
                      key={index}
                      sx={styles.projectDevelopmentDetailContainer}
                    >
                      <Typography
                        key={index}
                        variant="h6"
                        sx={styles.projectDevelopmentDetailText}
                      >
                        {item.description}
                      </Typography>
                      {item.imgs &&
                        item.imgs.map((img, index) => (
                          <Box sx={styles.projectDevelopmentDetailImageContainer} key={index}>
                            <Box
                              component="img"
                              src={require("../../" + img)} //DIR: "../../[Project Name]/img/[file]", require() can't get files from the parent directories when using variable, props.img
                              alt="Description Image"
                              sx={styles.projectDevelopmentDetailImage}
                            />
                          </Box>
                        ))}
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>
          </Box>
        }
      />
    </Container>
  )
}
