export const styles = {
    experienceContainer: {
        mt: '5rem',
        ml: 0,
    },
    experienceTitle: {
        fontFamily: 'inherit',
        mb: '2.5rem',
        fontWeight: 500,
    },
    experienceBox: {
        mb: '1.5rem',
    },
    experienceLogo: {
        width: '200px',
        mb: '1rem'
    },
    experienceDetailBox: {
        display: 'flex',
        justifyContent: 'space-between',
        mb: '1rem',
    },
    experiencePosition: {
        fontFamily: 'inherit',
    },
    experienceTerm: {
        fontFamily: 'inherit',
        fontWeight: 300,
        textAlign: 'right'
    },
    experienceResumeBox: {
        mt: '5rem',
        width: 'inherit',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    experienceResumeButton: {
        color: 'var(--brown)',
        fontFamily: 'inherit'
    },
    experienceResumeText: {
        fontFamily: 'inherit',
        color: 'var(--brown)'
    }
};