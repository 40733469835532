export const styles = {
    projectDetailBox: {
        display: 'flex',
        justifyContent: 'center',
        mt: '10rem'
    },
    projectDetailTitle: {
        fontFamily: 'inherit',
        fontWeight: 500,
        mb: '0.5rem'
    },
    projectDetailInfo: {
        fontFamily: 'inherit',
        fontWeight: 400,
        mb: '2rem'
    },
    projectDetailRoleList: {
        listStyle: 'circle',
        listStylePosition: 'outside',
        ml: '1.3em'
    },
    projectDetailRoleInfo: {
        fontFamily: 'inherit',
        fontWeight: 400,
    }
}