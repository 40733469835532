export const styles = {
    needsContainer: {
        mb: '10rem'
    },
    needsTitle: {
        fontFamily: 'inherit',
        fontWeight: 500,
        mb: '1rem'
    },
    needsDetail: {
        fontFamily: 'inherit',
        mt: '1rem',
    }
};