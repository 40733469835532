import { Container, Typography, Box, Button } from "@mui/material"

import { styles } from "./styles"

export default function ProjectTitle(props) {
  /* 
    Title component for project page
  */
  function redirectToProject(url) {
    window.open(url, "_blank")
  }
  return (
    <Container
      maxWidth="unset"
      sx={styles.projectPageTitleContainer}
      className="projectPageTitleContainer"
    >
      <Typography variant="h3" sx={styles.projectPageTitle}>
        {props.title}
      </Typography>
      <Typography variant="h5" sx={styles.projectPageSubtitle}>
        {props.subTitle}
      </Typography>
      {props.projectLink && (
        <Box sx={styles.projectLinkButtonBox}>
          <Button
            size="large"
            variant="text"
            sx={styles.projectLinkButton}
            className="projectLinkButton"
            onClick={() => redirectToProject(props.projectLink)}
          >
            {props.projectLinkTitle}
          </Button>
        </Box>
      )}
    </Container>
  )
}
