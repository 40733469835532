export const styles = {
    foodArchiveContainer: {
        mt: '3rem',
        maxWidth: 'fit-content!important'
    },
    foodArchiveTitle: {
        mb: '2rem',
        fontFamily: 'inherit',
        fontWeight: 500
    }
};